'use client';
import Typography from '@/components/shared/typography/typography.component';
import { AddressSrc, ILocation } from '@/types';
import Image from 'next/image';
import styles from './styles.module.scss';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef } from 'react';
import { useReverseGeocodeQuery } from '@/hook/geocoder-api-slice';
import colors from '@/theme/colors.module.scss';
import { useTranslation } from '@/hook';
import { NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY } from '@/constants';
import {
    Calendar,
    LocationPin,
    ShowRoom,
    Voucher,
    X,
    arrowAnimated,
    hubImage,
    hubImage1,
    hubImage2,
    hubImage3,
    hubImage4,
    hubImage5,
    hubImage6,
    saleIcon,
    shieldIcon,
    specialInspection,
    zapFastIcon,
} from '@/../public/Images';
import SylndrLocationIcon from '@/components/shared/sylndrLocationIcon/sylndr-location.component';
import myImageLoader from '@/image.loader';

const SylndrHubModal = ({
    closeModal,
    routeToSellForm,
}: {
    closeModal: () => void;
    routeToSellForm: () => void;
}) => {
    const { t, IS_RTL } = useTranslation();
    const hubBenfits = [
        { text: t('SameDayOffer'), url: zapFastIcon },
        { text: t('sellPage.WinVoucher'), url: saleIcon },
        { text: t('privacy'), url: shieldIcon },
        { text: t('sellPage.flexibleSchedule'), url: Calendar },
    ];
    const hubDetails = [
        {
            text: t('sellPage.Days'),
            url: Calendar,
            description: t('sellPage.daysDiscription'),
        },
        {
            text: t('sellPage.location'),
            url: LocationPin,
            description: t('mainShowroomAddress'),
        },
    ];

    const hubJourney = [
        {
            type: 'text',
            title: t('sellPage.specialTreatment'),
            description: t('sellPage.specialTreatmentDescription'),
        },
        {
            type: 'img',
            url: specialInspection.src,
        },
        {
            type: 'img',
            url: ShowRoom.src,
        },
        {
            type: 'text',
            title: t('sellPage.visitSylndr'),
            description: t('sellPage.visitSylndrDescription'),
        },
        {
            type: 'text',
            title: t('sellPage.buyingVoucher'),
            description: t('sellPage.tradeInOffer'),
        },
        {
            type: 'img',
            url: Voucher.src,
        },
    ];

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY,
    });

    const defaultLoc: ILocation = {
        city: 'cairo',
        address: 'sylndr',
        coordinate: { lat: 29.969183131024852, lng: 31.363160703973296 },
    };

    return (
        <div className={styles.container}>
            <div className={styles.header_container}>
                <Typography color={colors.gray700} variant="subtitle1SemiBold">
                    {t('sylndrHubTitle')}
                </Typography>
                <Image
                    src={X.src}
                    width={X.width}
                    height={X.height}
                    onClick={() => closeModal()}
                    style={{ cursor: 'pointer' }}
                    alt="close"
                />
            </div>
            <div className={styles.content_container}>
                <div className={styles.hub_images}>
                    {[
                        hubImage,
                        hubImage1,
                        hubImage2,
                        hubImage3,
                        hubImage4,
                        hubImage5,
                        hubImage6,
                    ].map((img, i) => (
                        <img
                            src={myImageLoader({
                                src: img.src,
                                height: 252,
                                width: 252,
                                quality: 95,
                            })}
                            key={i}
                            alt="hubImage"
                            style={{
                                marginLeft: IS_RTL ? '10px' : '0',
                                marginRight: !IS_RTL ? '10px' : '',
                            }}
                        />
                    ))}
                </div>
                <div>
                    <Typography
                        color={colors.gray700}
                        variant="subtitle2SemiBold"
                    >
                        {t('sellPage.hubBenefits')}
                    </Typography>
                    <div className={styles.hub_benefits}>
                        {hubBenfits.map((item, i) => (
                            <div className={styles.benefit} key={i}>
                                <div className={styles.benefit_img}>
                                    <Image
                                        src={item.url}
                                        width={20}
                                        height={20}
                                        alt="icon"
                                    />
                                </div>

                                <Typography
                                    variant="caption"
                                    style={{ textAlign: 'center' }}
                                >
                                    {item.text}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <Typography
                        variant="subtitle2SemiBold"
                        style={{ marginTop: '24px' }}
                    >
                        {t('sellPage.hubDetails')}
                    </Typography>
                    <div className={styles.hub_details_cont}>
                        {hubDetails.map((item, i) => (
                            <div className={styles.hub_details} key={i}>
                                <div>
                                    <Image
                                        src={item.url}
                                        width={20}
                                        height={20}
                                        alt={item.text}
                                    />
                                    <Typography variant="body2Medium">
                                        {item.text}
                                    </Typography>
                                </div>
                                <Typography variant="body2">
                                    {item.description}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>

                {isLoaded && window?.google && (
                    <div className={styles.map_container}>
                        <Map
                            location={defaultLoc}
                            addressSrc="reverseGeolocation"
                        />
                        <div className={styles.location_icon}>
                            <SylndrLocationIcon
                                sourceButton={'Location screen'}
                            />
                        </div>
                    </div>
                )}

                <div className={styles.hub_journey_cont}>
                    <Typography
                        variant="subtitle2SemiBold"
                        style={{ marginTop: '24px' }}
                    >
                        {t('sellPage.hubJourney')}
                    </Typography>
                    <small>{t('sellPage.hubJourneyDescribtion')}</small>
                    <div className={styles.hub_journey}>
                        {hubJourney.map((step, i) =>
                            step.type === 'text' ? (
                                <div className={styles.hub_step} key={i}>
                                    <Typography variant="subtitle2SemiBold">
                                        {step.title}
                                    </Typography>
                                    <Typography variant="body2Medium">
                                        {step.description}
                                    </Typography>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '35%',
                                    }}
                                    key={i}
                                >
                                    <img
                                        src={step.url}
                                        width={'75%'}
                                        alt="step"
                                    ></img>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.button_hint_cont}>
                <div className={styles.next_step}>{t('sellPage.nextStep')}</div>
                <button
                    onClick={() => {
                        routeToSellForm();
                    }}
                    className={`sylndr_button primary ${styles.button}`}
                >
                    <Typography variant="body1Medium">
                        {t('sellPage.getYourPrice')}
                    </Typography>
                    <Image
                        style={{
                            transform: IS_RTL ? 'scaleX(-1)' : '',
                        }}
                        src={arrowAnimated.src}
                        alt="arrow"
                        width={20}
                        height={20}
                    />
                </button>
            </div>
        </div>
    );
};
export default SylndrHubModal;

const Map = ({
    location,
    addressSrc,
}: {
    location: ILocation;
    addressSrc: AddressSrc;
}) => {
    const defaultMapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        minZoom: 10,
        maxZoom: 20,
        streetViewControl: false,
        gestureHandling: 'greedy',
        zoomControl: false,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
        },
    };

    const refMap = useRef<google.maps.Map | null>(null);

    useReverseGeocodeQuery(
        {
            latLng: location.coordinate || { lat: 30.0444, lng: 31.2357 },
            lang: 'ar',
        },
        { skip: addressSrc === 'autocomplete' }
    );

    useEffect(() => {
        if (refMap?.current) {
            const currCenter = refMap.current.getCenter()?.toJSON();
            if (
                currCenter?.lat !== location.coordinate.lat ||
                currCenter.lng !== location.coordinate.lng
            ) {
                refMap.current.setZoom(16);
                refMap.current.panTo(location.coordinate);
            }
        }
    }, [location.coordinate, refMap?.current]);

    const handleLoad = useCallback((map: google.maps.Map) => {
        refMap.current = map;
        refMap.current.setCenter(location.coordinate);
        refMap.current.setZoom(location.address ? 16 : 13);
    }, []);
    const latLng = {
        lat: location.coordinate.lat,
        lng: location.coordinate.lng,
    };
    return (
        <>
            {/* @ts-ignore */}
            <GoogleMap
                mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '8px',
                    border: '2px solid',
                    borderColor: colors.gray200,
                }}
                options={defaultMapOptions}
                mapContainerClassName="map-container"
                onLoad={handleLoad}
            >
                <div
                    style={{
                        width: '100%',
                        padding: '16px',
                    }}
                ></div>
                <div
                    style={{
                        position: 'absolute',
                        left: 'calc(50% - 13.5px)',
                        top: 'calc(50% - 49px)',
                    }}
                >
                    <MarkerF position={latLng} title={'sylndr'} />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '16px',
                        right: '16px',
                    }}
                ></div>
            </GoogleMap>
        </>
    );
};
