'use client';
import Typography from '@/components/shared/typography/typography.component';
import { useTranslation, useWindowDimensions } from '@/hook';
import styles from './styles.module.scss';
import Image from 'next/image';
import { FC, useEffect, useRef, useState } from 'react';
import {
    arrowAnimated,
    ArrowDownTwisted,
    CarInspection,
    chevronGreenArrow,
    circleCheck,
    GetOurOffer,
    greenArrowAnimated,
    HassleFree,
    hintIcon,
    HomeButton,
    instantOffer,
    InstantPayment,
    LocationPin,
    magnifireIcon,
    noHidden,
    playButton,
    sellPageBanner,
    sellPageBannerEnglish,
    SsylndrIcon,
    startIcon,
    underLine,
} from '@/../public/Images';
import { EInspectionType, ExpirementInspectionType } from '@/types';
import colors from '@/theme/colors.module.scss';
import i18n from '@/translations/client-setup';
import { useRouter } from 'next/navigation';
import { Box, Paper } from '@mui/material';
import CountUp from 'react-countup';
import SwipeableDrawer from '@/components/shared/bottom-sheet/bottom-sheet.component';
import SylndrHubModal from './sylndrHubLocation/sylndr-hub-modal.component';

import { useAnalytics } from '@/integrations';
import ReactPlayer from 'react-player';
import SylndrLocationIcon from '@/components/shared/sylndrLocationIcon/sylndr-location.component';
import myImageLoader from '@/image.loader';
import { useHash } from '@/hook/use-hash';
import { useSteps } from '../sell-car-atomic-form/stepperCore/useSteps';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
    AVAILABLE_LANGUAGES,
    FEATURE_FLAG_WAREHOUSE_INSPECTION_SELECTION_NEW_PAGE,
} from '@/constants';
import { DimensionsTargets } from '@/hook/use-window-width.hook';

const MobileSellCarPage: FC = () => {
    const { t, IS_RTL } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hash, setHashUrl, resetHashUrl } = useHash();
    const router = useRouter();
    const experimentInspectionType = useFeatureValue(
        FEATURE_FLAG_WAREHOUSE_INSPECTION_SELECTION_NEW_PAGE,
        ExpirementInspectionType.WAREHOUSE
    ) as ExpirementInspectionType;
    const { inspectionTypeStep } = useSteps();
    const { IS_DESKTOP } = useWindowDimensions(DimensionsTargets.MOBILE);
    const lang = AVAILABLE_LANGUAGES[i18n.language];
    const bannerContainerRef = useRef<HTMLDivElement>(null);
    const inspectionButtonsValuePropositionRef = useRef<HTMLDivElement>(null);
    const howSylndrInspectCarVidRef = useRef<HTMLDivElement>(null);
    const sylndrHubBottomSheetHash = 'SylndrHub';
    const wareHouseValueProposition = [
        t('SameDayOffer'),
        t('sellPage.voucher'),
        t('sellPage.privateSellingProcess'),
    ];
    const homeInspectionValueProposition = [
        t('sellPage.sameDayOffer'),
        t('sellPage.doorStepDelivery'),
        t('sellPage.QuickInspection'),
    ];
    [
        'CarInspection',
        'CarInspectionDescription',
        'GetOurOffer',
        'GetOurOfferDescription',
        'InstantCashAndOwnership',
        'InstantCashAndOwnershipDescription',
    ];
    const stepsToSellCar = [
        {
            type: 'img',
            url: CarInspection.src,
        },
        {
            type: 'text',
            title: t('sellPage.CarInspection'),
            description: t('sellPage.CarInspectionDescription'),
        },
        {
            type: 'text',
            title: t('sellPage.GetOurOffer'),
            description: t('sellPage.GetOurOfferDescription'),
        },
        {
            type: 'img',
            url: GetOurOffer.src,
        },
        {
            type: 'img',
            url: InstantPayment.src,
        },

        {
            type: 'text',
            title: t('sellPage.InstantCashAndOwnership'),
            description: t('sellPage.InstantCashAndOwnershipDescription'),
        },
    ];

    const [warehouseInspectionActive, setWarehouseInspectionActive] = useState(
        experimentInspectionType === ExpirementInspectionType.WAREHOUSE
            ? true
            : false
    );
    const [shownPropostion, setShownProposition] = useState(
        wareHouseValueProposition
    );
    const [openHubModal, setOpenHubModal] = useState(false);
    const [isStickyQuestion, setIsStickyQuestion] = useState(false);

    const handleScroll = () => {
        if (
            !inspectionButtonsValuePropositionRef?.current ||
            !bannerContainerRef?.current ||
            !howSylndrInspectCarVidRef.current
        )
            return;
        const navBarHeight = 80;
        const { height: bannerSectionHeight } =
            bannerContainerRef.current.getBoundingClientRect();
        inspectionButtonsValuePropositionRef.current.getBoundingClientRect();
        const { height: section1Height } =
            inspectionButtonsValuePropositionRef.current.getBoundingClientRect();
        const { height: howSylndrInspectCarVidHeight } =
            howSylndrInspectCarVidRef.current.getBoundingClientRect();
        if (
            window.scrollY >
            section1Height +
                bannerSectionHeight +
                navBarHeight +
                howSylndrInspectCarVidHeight
        ) {
            setIsStickyQuestion(true);
        } else {
            setIsStickyQuestion(false);
        }
    };

    const setModelAndHash = (openFlag: boolean) => {
        setOpenHubModal(openFlag);
        openFlag ? setHashUrl(sylndrHubBottomSheetHash) : resetHashUrl();
    };

    useEffect(() => {
        setWarehouseInspectionActive(
            experimentInspectionType === ExpirementInspectionType.WAREHOUSE
        );
    }, [experimentInspectionType]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (
            (hash === sylndrHubBottomSheetHash ||
                hash === sylndrHubBottomSheetHash.toLowerCase()) &&
            !IS_DESKTOP
        ) {
            setOpenHubModal(true);
        }
        if (IS_DESKTOP) resetHashUrl();
    }, [hash, IS_DESKTOP]);

    const inspectionTypeSelected = (id: EInspectionType) => {
        inspectionTypeStep.setValue({
            id,
            arName: t(id),
            enName: t(id),
            inShoppingList: true,
        });
    };

    const routeToSellForm = (sourceButton: string) => {
        if (warehouseInspectionActive) {
            inspectionTypeSelected(EInspectionType.WAREHOUSE);
        } else {
            inspectionTypeSelected(EInspectionType.HOME);
        }
        trackEvent('Sell_CTA', {
            inspectionType: warehouseInspectionActive
                ? EInspectionType.WAREHOUSE
                : EInspectionType.HOME,
            sourceButton,
        });
        router.push(`/${lang}/sell-form`);
    };
    return (
        <div>
            <div
                className={styles.banners_container_mobile}
                ref={bannerContainerRef}
            >
                <img
                    src={
                        IS_RTL
                            ? myImageLoader({
                                  src: sellPageBanner.src,
                                  width: 400,
                                  height: 150,
                                  quality: 97,
                              })
                            : myImageLoader({
                                  src: sellPageBannerEnglish.src,
                                  width: 400,
                                  height: 150,
                                  quality: 97,
                              })
                    }
                    alt="banner"
                />
            </div>

            <Box
                className={styles.page_container}
                ref={inspectionButtonsValuePropositionRef}
            >
                <div
                    className={
                        isStickyQuestion ? styles.is_sticky_question : ''
                    }
                >
                    <div
                        className={`${styles.inspection_location_question} mt-[10px]`}
                    >
                        <Typography variant="subtitle2SemiBold">
                            {t('sellPage.inspectionQuestion')}
                        </Typography>
                        <span
                            className={IS_RTL ? styles.reverse_down_arrow : ''}
                        >
                            <Image
                                src={ArrowDownTwisted}
                                alt="arrow-down"
                            ></Image>
                        </span>
                    </div>
                    <div className={styles.value_proposition_buttons}>
                        <button
                            className={
                                !warehouseInspectionActive ? styles.active : ''
                            }
                            onClick={() => {
                                setWarehouseInspectionActive(false);
                                setShownProposition(
                                    homeInspectionValueProposition
                                );
                            }}
                        >
                            <span>
                                <Image src={HomeButton} alt="home-icon"></Image>
                            </span>

                            <Typography variant="body2SemiBold">
                                {t('InspectionAtHomeMobile')}
                            </Typography>
                        </button>
                        <button
                            className={
                                warehouseInspectionActive ? styles.active : ''
                            }
                            onClick={() => {
                                setWarehouseInspectionActive(true);
                                setShownProposition(wareHouseValueProposition);
                            }}
                        >
                            <span>
                                <Image src={SsylndrIcon} alt="s-icon" />
                            </span>

                            <Typography variant="body2SemiBold">
                                {t('InspectionAtSylndrHubMobile')}
                            </Typography>
                        </button>
                    </div>
                    <div
                        className={
                            isStickyQuestion
                                ? styles.sticky_step
                                : styles.hidden
                        }
                    >
                        <Typography
                            variant="captionMedium"
                            className={styles.next_step}
                        >
                            {t('sellPage.nextStep')}
                        </Typography>
                        <button
                            className={styles.next_button}
                            onClick={() => {
                                routeToSellForm('Top Screen');
                            }}
                        >
                            <Typography variant="body1Medium">
                                {t('next')}
                            </Typography>
                            <Image
                                src={greenArrowAnimated.src}
                                style={{
                                    transform: IS_RTL ? 'scaleX(-1)' : '',
                                }}
                                alt="arrow"
                                width={20}
                                height={20}
                            />{' '}
                        </button>
                    </div>
                </div>

                <div className={styles.value_propositions}>
                    {shownPropostion?.map((item, i) => (
                        <div key={i}>
                            <Image src={circleCheck} alt="checked"></Image>{' '}
                            {item}
                        </div>
                    ))}
                    <section
                        className={
                            !warehouseInspectionActive
                                ? styles.see_more_hidden
                                : styles.see_more
                        }
                    >
                        <button
                            onClick={() => {
                                setModelAndHash(true);
                            }}
                        >
                            {t('learn more')}
                        </button>
                        <Image
                            src={chevronGreenArrow}
                            alt="checked"
                            className={IS_RTL ? styles.reverse_down_arrow : ''}
                        ></Image>
                        <SwipeableDrawer
                            open={openHubModal}
                            setOpen={setOpenHubModal}
                            onClose={() => setModelAndHash(false)}
                            drawerHeight={'90%'}
                        >
                            <SylndrHubModal
                                closeModal={() => setModelAndHash(false)}
                                routeToSellForm={() =>
                                    routeToSellForm('Location screen')
                                }
                            />
                        </SwipeableDrawer>
                    </section>
                </div>
                <div className={styles.sepration_line}></div>
                {warehouseInspectionActive ? (
                    <div className={styles.location_info_container}>
                        <div className={styles.hub_pin_container}>
                            <div>
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        src={LocationPin.src}
                                        width={16}
                                        height={16}
                                        alt="working hours"
                                    />
                                    <Typography
                                        variant="body2SemiBold"
                                        color={colors.primary}
                                    >
                                        {t('sylndrHubTitle')}
                                    </Typography>
                                </div>

                                <div className={styles.directions}>
                                    <Typography
                                        variant="body2"
                                        color={colors.gray500}
                                    >
                                        {t('mainShowroomAddress')}
                                    </Typography>
                                    <SylndrLocationIcon
                                        sourceButton={'Sell Page'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.loaction_hint}>
                        <Image src={hintIcon} alt="hint" />
                        <span>{t('sellPage.WeWillComeToYourLocation')}</span>
                    </div>
                )}
                <Typography
                    variant="captionMedium"
                    className={styles.next_step}
                >
                    {t('sellPage.nextStep')}
                </Typography>
                <button
                    onClick={() => {
                        routeToSellForm('Sell Page');
                    }}
                    className={`sylndr_button primary ${styles.button}`}
                >
                    <Typography variant="body1Medium">
                        {t('sellPage.getYourPrice')}
                    </Typography>
                    <Image
                        style={{
                            transform: IS_RTL ? 'scaleX(-1)' : '',
                        }}
                        src={arrowAnimated.src}
                        alt="arrow"
                        width={20}
                        height={20}
                    />{' '}
                </button>
            </Box>
            <Box
                sx={{
                    marginTop: '48px',
                }}
                className={styles.sections_cont}
                ref={howSylndrInspectCarVidRef}
            >
                <h2>{t('sellPage.howSylndrInspectCar')}</h2>
                <div className={styles.inspection_vid_cont}>
                    <ReactPlayer
                        url={
                            'https://sylndr-website.s3.us-east-2.amazonaws.com/vid-min-quality/sellPageMain.mp4'
                        }
                        width={'100%'}
                        height={'100%'}
                        controls={true}
                        playing={true}
                        playsinline={true}
                        light={
                            <img
                                src={myImageLoader({
                                    src: 'https://sylndr-website.s3.us-east-2.amazonaws.com/vid/Acquisition+Video+thumbnail.png',
                                    width: 400,
                                    height: 300,
                                    quality: 95,
                                })}
                                width={'100%'}
                                height={'100%'}
                                style={{
                                    objectFit: 'cover',
                                }}
                                alt="play"
                            ></img>
                        }
                        playIcon={
                            <span
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <Image
                                        src={playButton.src}
                                        width={60}
                                        height={60}
                                        alt="play"
                                    />
                                </div>
                            </span>
                        }
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    controls: true,
                                },
                            },
                        }}
                        onPlay={() => {
                            trackEvent('Action: Play_Inspection_Video');
                        }}
                    />
                </div>
            </Box>
            <Box className={styles.sections_cont}>
                <h2
                    style={{
                        position: 'relative',
                        marginTop: '48px',
                        marginBottom: '32px',
                    }}
                >
                    <span style={{ position: 'relative' }}>
                        <Image
                            src={underLine.src}
                            width={underLine.width}
                            height={underLine.height}
                            style={{
                                right: '0px',
                                left: '0px',
                                position: 'absolute',
                                bottom: '-50%',
                            }}
                            alt="line"
                        />
                        {t('sellPage.3steps')}
                    </span>{' '}
                    {t('sellPage.stepsToSellYourCar')}
                </h2>
                <div className={styles.steps_to_cell}>
                    {stepsToSellCar.map((step, i) =>
                        step.type === 'text' ? (
                            <div className={styles.sell_step} key={i}>
                                <Typography variant="subtitle2SemiBold">
                                    {step.title}
                                </Typography>
                                <Typography variant="body2Medium">
                                    {step.description}
                                </Typography>
                            </div>
                        ) : (
                            <img
                                key={i}
                                alt="step image"
                                src={step.url}
                                width={'100%'}
                            ></img>
                        )
                    )}
                </div>
            </Box>
            <Box className={styles.why_sell}>
                <h2>{t('sellPage.whySellYourCar')}</h2>
                <Paper elevation={0} className={styles.why_sell_card}>
                    <div className={'flex justify-center items-center'}>
                        <Image
                            src={instantOffer.src}
                            alt="offer"
                            width={60}
                            height={60}
                        />
                    </div>
                    <Typography
                        variant="subtitle2SemiBold"
                        className={'text-center'}
                    >
                        {t('sellPage.InstantOffer')}
                    </Typography>
                    <Typography variant="body2Medium" className={'text-center'}>
                        {t('sellPage.InstantOfferDescription')}
                    </Typography>
                </Paper>
                <div className={styles.why_sell_grid}>
                    <Paper
                        elevation={0}
                        sx={{
                            padding: '20px',
                            borderRadius: '10px',
                        }}
                    >
                        <div className={'flex justify-center items-center'}>
                            <Image
                                src={HassleFree.src}
                                alt="offer"
                                width={60}
                                height={60}
                            />
                        </div>
                        <Typography
                            variant="subtitle2SemiBold"
                            style={{ textAlign: 'center' }}
                        >
                            {t('sellPage.FreeHomeInspection')}
                        </Typography>
                        <Typography
                            variant="body2Medium"
                            style={{ textAlign: 'center' }}
                        >
                            {t('sellPage.FreeHomeInspectionDescription')}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={0}
                        sx={{
                            padding: '20px',
                            borderRadius: '10px',
                        }}
                    >
                        <div className={'flex justify-center items-center'}>
                            <Image
                                src={noHidden.src}
                                alt="offer"
                                width={60}
                                height={60}
                            />
                        </div>
                        <Typography
                            variant="subtitle2SemiBold"
                            style={{ textAlign: 'center' }}
                        >
                            {t('sellPage.NoHiddenCosts')}
                        </Typography>
                        <Typography
                            variant="body2Medium"
                            style={{ textAlign: 'center' }}
                        >
                            {t('sellPage.NoHiddenCostsDescription')}
                        </Typography>
                    </Paper>
                </div>

                <button
                    className={`sylndr_button primary ${styles.button_small}`}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                        trackEvent('Action: Sell_a_Car');
                    }}
                >
                    <Typography variant="body1Medium">
                        {t('Sell Car')}
                    </Typography>
                </button>
            </Box>
            <Box
                sx={{
                    marginTop: '30px',
                    padding: '15px',
                }}
                className={styles.counter_section}
            >
                <div className={styles.counter}>
                    <div className={styles.count_img}>
                        <Image
                            src={magnifireIcon.src}
                            alt="offer"
                            width={25}
                            height={25}
                        />
                    </div>
                    <CountUp
                        start={0}
                        end={23710}
                        duration={3.5}
                        separator=","
                        enableScrollSpy={true}
                        className={styles.count_num}
                    >
                        {({ countUpRef }) => (
                            <span
                                ref={countUpRef}
                                className={styles.count_num}
                            />
                        )}
                    </CountUp>
                    <Typography
                        variant="subtitle2SemiBold"
                        style={{ textAlign: 'center' }}
                    >
                        {t('sellPage.CarsInspected')}
                    </Typography>
                </div>
                <div className={styles.counter}>
                    <div className={styles.count_img}>
                        <Image
                            src={startIcon.src}
                            alt="offer"
                            width={23}
                            height={23}
                        />
                    </div>
                    <CountUp
                        start={0}
                        end={4.6}
                        duration={4.5}
                        decimal="."
                        decimals={1}
                        separator=","
                        enableScrollSpy={true}
                        className={styles.count_num}
                    >
                        {({ countUpRef }) => (
                            <span
                                ref={countUpRef}
                                className={styles.count_num}
                            />
                        )}
                    </CountUp>
                    <Typography
                        variant="subtitle2SemiBold"
                        style={{ textAlign: 'center' }}
                    >
                        {t('sellPage.clientsRating')}
                    </Typography>
                </div>
            </Box>
        </div>
    );
};

export default MobileSellCarPage;
