import {
    kilometers,
    Model,
    Transmission,
    Year,
} from '@/types/acquistion/atomic-sell-form.types';

export const getYears = (model: Model): Year[] => {
    return model.yearsKm
        .map(year => ({
            enName: year.year,
            arName: year.year,
            maxKm: year.km,
            id: year.year,
            image: model.image,
            inShoppingList: year.inShoppingList,
            manualAccepted: year.manualAccepted,
        }))
        .reverse();
};

export const getKilometers = (year: Year): kilometers[] => {
    const maxKM = 220000;
    let start = 0;
    let end = 9999;
    const step = 10000;
    const result = [];
    while (end + 1 < maxKM) {
        const kilometerRange = `${start.toLocaleString()} - ${end.toLocaleString()}`;
        result.push({
            id: end,
            enName: kilometerRange,
            arName: kilometerRange,
            inShoppingList:
                end <= +year.maxKm &&
                !(['2022', '2023'].includes(year.id) && end === 9999),
            image: year.image,
        });
        start += step;
        end += step;
    }
    if (start < maxKM) {
        const kilometerRange = `${start.toLocaleString()} - ${maxKM.toLocaleString()}`;
        result.push({
            enName: kilometerRange,
            arName: kilometerRange,
            id: maxKM,
            inShoppingList: end <= +year.maxKm,
            image: year.image,
        });
    }

    return result;
};

export const getTransmition = (manualAccepted: boolean, t): Transmission[] => {
    return [
        {
            arName: t('automatic'),
            enName: t('automatic'),
            id: t('automatic'),
            inShoppingList: true,
        },
        {
            arName: t('manual'),
            enName: t('manual'),
            id: t('manual'),
            inShoppingList: manualAccepted,
        },
    ];
};
