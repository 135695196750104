import { ICoordinate } from '../location.types';

export enum Steps {
    inspectionType = 'INSPECTIONTYPE',
    Make = 'MAKE',
    Model = 'MODEL',
    Year = 'YEAR',
    Kilometer = 'KILOMETERS',
    Transmition = 'TRANSMISSION',
    TrafficPoint = 'TRAFFICPOINT',
    Location = 'REGION',
    ContactInfo = 'CONTACTINFO',
}

export interface Step<T> {
    name: Steps;
    displayName: string;
    inputPlaceHolder?: string;
    header: string;
    icon: string;
    iconSecondary: string;
    value: T;
    setValue: (arg: T) => void;
    noneDependantSteps: Steps[];
    checkSkipArray: (() => boolean)[];
    defaultValue: T;
    getData?: () => Promise<T[]> | T[];
    replaceAllOnNext: boolean;
    GAEventFun?: (index: number) => void;
}

export enum EInspectionType {
    WAREHOUSE = 'warehouse',
    HOME = 'HomeInspection',
}

export enum EInspectionCases {
    CASE_1 = 'case1',
    CASE_2 = 'case2',
    CASE_3 = 'case3',
    CASE_NONE = 'caseNone',
    CASE_WAREHOUSE_INSPECTION = 'caseWarehouseInspection',
}

export enum EStepperPaths {
    PATH_1 = 'path1',
    PATH_2 = 'path2',
}

export interface InspectionType extends Omit<genericStep, 'id'> {
    id: EInspectionType;
    inShoppingList: boolean;
}
export interface genericStep {
    enName: string;
    arName: string;
    image?: string;
    id: string;
    inShoppingList: boolean;
}

export type Make = genericStep;

export interface Model extends genericStep {
    inShoppingList: boolean;
    years: string[];
    yearsKm: {
        year: string;
        km: string;
        manualAccepted: boolean;
        inShoppingList: boolean;
    }[];
}

export interface Year extends genericStep {
    manualAccepted: boolean;
    maxKm: string;
}

export type kilometers = genericStep;

export type Kilometers = genericStep;

export type Transmission = genericStep;

export interface LocationStep {
    id: string;
    enName: string;
    arName: string;
    bigArea: string;
    bigAreaAr: string;
    smallArea: string;
    smallAreaAr: string;
    name: string;
    address: string;
    coordinate: ICoordinate | null;
    polygon: ICoordinate[];
    warehouse: boolean;
    serviceable: boolean;
    addressCity: string;
    inShoppingList: boolean;
    sendPinLocation: boolean;
}

export interface IContactInfo {
    enName?: string;
    arName?: string;
    name: string;
    phone: string;
    email: string;
    userId?: string;
    inShoppingList?: boolean;
}
export type orderedStep = Step<any> & { OrderedStepsIndex: number };

export type StepperPath = 'path1' | 'path2';
