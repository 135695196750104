import {
    AcquisitionDesktopHeader,
    FAQs,
    HowItWorksSection,
    TestimonialSection,
    WhySellYourCarSection,
} from '@/components/acquisition';

import styles from './styles.module.scss';
import MobileSellCarPage from './mobile-sell-car-page/mobile-sell-car.component';
import VidTestimonialsSection from '@/components/testimonialVidSection/vid-testimonials.component';
import { Box } from '@mui/material';
import { ServiceName } from '@/constants';

const AcquisitionPage = () => {
    return (
        <div>
            <div className={styles.desktop_header}>
                <AcquisitionDesktopHeader />
                <HowItWorksSection />
                <TestimonialSection />
                <WhySellYourCarSection />
                <FAQs />
            </div>
            <div className={styles.banner_contianer}>
                <MobileSellCarPage />
                <Box className={styles.testimonialContainer}>
                    <VidTestimonialsSection 
                        SourceScreen="Sell Car"
                        serviceName={ServiceName.ACQUISITION}
                    />
                </Box>
                <FAQs />
            </div>
        </div>
    );
};

export default AcquisitionPage;
