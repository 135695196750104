import Link from 'next/link';
import { sylndrDirections } from '@/constants';
import { useAnalytics } from '@/integrations';
import { useTranslation } from '@/hook';
import Image from 'next/image';
import { EInspectionType } from '@/types';
import { directionsIcon } from 'public/Images';

const SylndrLocationIcon = ({ sourceButton }: { sourceButton: string }) => {
    const { IS_RTL } = useTranslation();
    const { trackEvent } = useAnalytics();

    return (
        <Link
            prefetch={false}
            href={sylndrDirections}
            target="_blank"
            onClick={() => {
                trackEvent('Action: Sell_Map_Icon', {
                    inspectionType: EInspectionType.WAREHOUSE,
                    sourceButton,
                });
            }}
        >
            <Image
                style={{
                    transform: IS_RTL ? 'scaleX(-1)' : '',
                }}
                src={directionsIcon.src}
                width={40}
                height={40}
                alt="get directions"
                className="max-w-none"
            />
        </Link>
    );
};

export default SylndrLocationIcon;
