import { DESKTOP_SCREEN_LABEL, MOBILE_SCREEN_LABEL } from '@/constants/sylndr';
import { useTranslation, useWindowDimensions } from '@/hook';
import { useAnalytics } from '@/integrations/analytics.integration';
import { useStepperValues } from './utils';
import {
    IContactInfo,
    genericStep,
} from '@/types/acquistion/atomic-sell-form.types';

interface AnalyticsObject {
    make?: string;
    model?: string;
    year?: string;
    kilometers?: string;
    area?: string;
    zone?: string;
    name?: string;
    mobileNumber?: string;
    email?: string;
    source?: string;
    lang?: string;
    available?: boolean;
    transmission?: string;
    trafficPoint?: string;
    pinLocationUsed?: boolean;
    lead_id?: string;
}

const useStepperAnalytics = () => {
    const { trackEvent } = useAnalytics();
    const { i18n } = useTranslation();
    const { IS_DESKTOP } = useWindowDimensions();
    const screenSource = IS_DESKTOP
        ? DESKTOP_SCREEN_LABEL
        : MOBILE_SCREEN_LABEL;

    const {
        inspectionType,
        make,
        model,
        year,
        kilometers,
        transmission,
        region,
        contactInfo,
    } = useStepperValues();

    const isStepPopulated = (
        step: genericStep | IContactInfo,
        stepAnalyticsProps: object
    ) => {
        if (step) return stepAnalyticsProps;
        else null;
    };

    const orderedStepsAnalyticsObjects: object[] = [
        isStepPopulated(inspectionType, {}),
        isStepPopulated(make, {
            make: make?.enName,
            available: make?.inShoppingList,
        }),
        isStepPopulated(model, {
            model: model?.enName,
            available: model?.inShoppingList,
        }),
        isStepPopulated(year, {
            year: year?.id,
            available: year?.inShoppingList,
        }),
        isStepPopulated(kilometers, {
            kilometers: kilometers?.id.toString(),
            available: kilometers?.inShoppingList,
        }),
        isStepPopulated(transmission, {
            transmission: transmission?.id,
            available: transmission?.inShoppingList,
        }),
        isStepPopulated(region, {
            area: region?.bigArea,
            zone: region?.smallArea,
            pinLocationUsed: region?.address !== '',
            available: region?.serviceable,
        }),
        isStepPopulated(contactInfo, {
            email: contactInfo?.email,
            name: contactInfo?.name,
            mobileNumber: contactInfo?.phone,
            available: contactInfo?.inShoppingList,
        }),
    ];

    const createAnalyticsObject = (stepIndex: number): AnalyticsObject => {
        let analyticsObject = {
            lang: i18n.language,
            screenSource,
        };
        orderedStepsAnalyticsObjects.forEach((step, i) => {
            if (step && i <= stepIndex) {
                analyticsObject = {
                    ...analyticsObject,
                    ...step,
                };
            }
        });

        return analyticsObject;
    };

    const stepGA_Event = (
        eventName: string,
        stepIndex: number,
        props?: object
    ) => {
        const analyticsObject = props
            ? { ...props, ...createAnalyticsObject(stepIndex) }
            : createAnalyticsObject(stepIndex);
        trackEvent(eventName, analyticsObject);
    };

    return { stepGA_Event };
};

export default useStepperAnalytics;
