'use client';
// import { useCheckers } from './useCheckers';
import { useAtom } from 'jotai/index';
import {
    kilometerAtom,
    makeAtom,
    modelAtom,
    yearAtom,
    transmitionAtom,
    inspectionTypeAtom,
    contactInfoAtom,
    locationAtom,
} from '@/atomic-store/sell-form.atoms';
import {
    Steps,
    Step,
    Make,
    Model,
    Kilometers,
    Year,
    Transmission,
    InspectionType,
    LocationStep,
    IContactInfo,
} from '@/types/acquistion/atomic-sell-form.types';
import { fetchMakes, fetchModel, fetchRegions } from './stepperAsyncFun';
import {
    getKilometers,
    getYears,
    getTransmition,
} from './stepperDrivedDataFun';
import {
    CalendarIconGreen,
    CalendarIconOrange,
    ContactIconGreen,
    ContactIconOrange,
    KMIconGreen,
    KMIconOrange,
    LensGreenIcon,
    LensOrangeIcon,
    LocationIconGreen,
    LocationIconOrange,
    MakeIconGreen,
    MakeIconOrange,
    ModelIconGreen,
    ModelIconOrange,
    otherInfoIcon,
    otherInfoIconOrange,
} from '../../../../../public/Images';
import { useTranslation } from 'react-i18next';
import { EInspectionType, formatedRegion } from '@/types';
import { useCheckers } from './useCheckers';
import useStepperAnalytics from './useStepperAnalytics';

export const useSteps = () => {
    const { skipIfWarehouseInspectioin, skipIfOutshoppingList } = useCheckers();
    const { t } = useTranslation();
    const { stepGA_Event } = useStepperAnalytics();
    const [inspectionType, setInspectionType] = useAtom(inspectionTypeAtom);
    const [make, setMake] = useAtom(makeAtom);
    const [model, setModel] = useAtom(modelAtom);
    const [year, setYear] = useAtom(yearAtom);
    const [kilometer, setKilometer] = useAtom(kilometerAtom);
    const [transmition, setTransmition] = useAtom(transmitionAtom);
    const [location, setLocation] = useAtom(locationAtom);
    const [contactInfo, setContactInfo] = useAtom(contactInfoAtom);

    const inspectionTypeStep: Step<InspectionType | null> = {
        name: Steps.inspectionType,
        displayName: t('inspectionType'),
        header: t('selectYourInspectionType'),
        icon: LensGreenIcon,
        iconSecondary: LensOrangeIcon,
        value: inspectionType,
        setValue: setInspectionType,
        noneDependantSteps: [],
        checkSkipArray: [],
        defaultValue: null,
        replaceAllOnNext: true,
        GAEventFun: index =>
            stepGA_Event(
                inspectionType?.id === EInspectionType.WAREHOUSE
                    ? 'Sell_Warehouse_Inspection'
                    : 'Sell_Home_Inspection',
                index
            ),
    };
    const makeStep: Step<Make | null> = {
        name: Steps.Make,
        displayName: t('make'),
        header: t('whats your car make'),
        inputPlaceHolder: t('Select Make'),
        icon: MakeIconGreen,
        iconSecondary: MakeIconOrange,
        value: make,
        setValue: setMake,
        noneDependantSteps: [],
        checkSkipArray: [],
        defaultValue: null,
        getData: fetchMakes,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Make', index),
    };

    const modelStep: Step<Model | null> = {
        name: Steps.Model,
        displayName: t('model'),
        header: t('pick your model'),
        inputPlaceHolder: t('Select Model'),
        icon: ModelIconGreen,
        iconSecondary: ModelIconOrange,
        value: model,
        setValue: setModel,
        noneDependantSteps: [],
        checkSkipArray: [],
        getData: () => fetchModel(make),
        defaultValue: null,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Model', index),
    };

    const yearStep: Step<Year | null> = {
        name: Steps.Year,
        displayName: t('year'),
        header: t('pick your car year'),
        inputPlaceHolder: t('Select Year'),
        icon: CalendarIconGreen,
        iconSecondary: CalendarIconOrange,
        value: year,
        setValue: setYear,
        noneDependantSteps: [],
        checkSkipArray: [],
        getData: () => getYears(model),
        defaultValue: null,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Year', index),
    };

    const kilometerStep: Step<Kilometers | null> = {
        name: Steps.Kilometer,
        displayName: t('kilometers'),
        header: t('pick your car kilometrage'),
        inputPlaceHolder: t('Select KM ranges'),
        icon: KMIconGreen,
        iconSecondary: KMIconOrange,
        value: kilometer,
        setValue: setKilometer,
        noneDependantSteps: [],
        checkSkipArray: [],
        getData: () => getKilometers(year),
        defaultValue: null,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Kilometers', index),
    };

    const transmitionStep: Step<Transmission | null> = {
        name: Steps.Transmition,
        displayName: t('otherinfo'),
        header: t('otherinfo'),
        inputPlaceHolder: t('Select Other Info'),
        icon: otherInfoIcon,
        iconSecondary: otherInfoIconOrange,
        value: transmition,
        setValue: setTransmition,
        noneDependantSteps: [],
        checkSkipArray: [],
        defaultValue: null,
        getData: () => getTransmition(year?.manualAccepted, t),
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Transmission', index),
    };

    const locationStep: Step<LocationStep | formatedRegion | null> = {
        name: Steps.Location,
        displayName: t('region'),
        header: t('your location'),
        inputPlaceHolder: t('Select Location'),
        icon: LocationIconGreen,
        iconSecondary: LocationIconOrange,
        value: location,
        setValue: setLocation,
        noneDependantSteps: [],
        checkSkipArray: [skipIfWarehouseInspectioin, skipIfOutshoppingList],
        defaultValue: null,
        getData: fetchRegions,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Region', index),
    };

    const contactInfoStep: Step<IContactInfo | null> = {
        name: Steps.ContactInfo,
        displayName: t('contactInfo'),
        header: t('contactInfoHeader'),
        icon: ContactIconGreen,
        iconSecondary: ContactIconOrange,
        value: contactInfo,
        setValue: setContactInfo,
        noneDependantSteps: [],
        checkSkipArray: [],
        defaultValue: null,
        replaceAllOnNext: true,
        GAEventFun: index => stepGA_Event('Sell_Personal_Details', index),
    };
    return {
        inspectionTypeStep,
        makeStep,
        modelStep,
        yearStep,
        kilometerStep,
        transmitionStep,
        locationStep,
        contactInfoStep,
    };
};
